import { flow, makeObservable, observable } from 'mobx'

import { notification } from 'services'
import { uniqBy } from 'utils'
import { UserApi } from 'api'

import BaseTasksStore from '../BaseTasksStore'

const defaultConfig = {
  statuses: [],
}

const initialSelectedFilters = {
  statuses: [],
}

export default class TasksPersonalStore extends BaseTasksStore {
  configLoaded = false

  constructor() {
    super()

    this.settingsKey = 'tasks-personal'
    this.selectedFilters = JSON.parse(JSON.stringify(initialSelectedFilters))
    this.initialSelectedFilters = initialSelectedFilters

    makeObservable(this, {
      configLoaded: observable,
      loadIssues: flow.bound,
      loadMoreIssues: flow.bound,
      loadConfig: flow.bound,
    })

    this.applySavedFilters()
  }

  *loadIssues() {
    this.issuesLoading = true
    this.issuesLoaded = false

    try {
      const { data, currentPage, totalPages } = yield UserApi.getPersonalTasks(this.getListParams())

      this.issues = data.data

      this.pagination.setCurrentPage(currentPage)
      this.pagination.totalPages = totalPages
    } catch (ex: any) {
      notification.error('Ошибка загрузки задач')
      console.log(ex?.message)
    } finally {
      this.issuesLoading = false
      this.issuesLoaded = true
    }
  }

  *loadMoreIssues() {
    const isEndOfData = this.pagination.currentPage > this.pagination.totalPages

    if (!isEndOfData && !this.loadingMore) {
      this.loadingMore = true

      try {
        const { data, currentPage } = yield UserApi.getPersonalTasks(this.getListParams())

        this.issues = uniqBy('id', [...this.issues, ...data.data])
        this.pagination.setCurrentPage(currentPage)
      } catch (ex: any) {
        notification.error('Ошибка загрузки задач')
        console.log(ex?.message)
      } finally {
        this.loadingMore = false
      }
    }
  }

  *loadConfig() {
    this.loading = true
    try {
      const { data } = yield UserApi.getPersonalFilters()
      this.config = data.data || defaultConfig
    } catch (ex: any) {
      console.log(ex?.message)
    } finally {
      this.loading = false
      this.configLoaded = true
    }
  }
}

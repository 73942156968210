import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { NoData, PageSpinner, Spinner } from 'components'
import { analyticsContext } from 'contexts'
import type { IProjectItem } from 'types'

import ProjectItem from './ProjectItem'

import './styles.scss'

interface ProjectListProps {
  items: IProjectItem[]
  loading: boolean
  loadingMore: boolean
}

const ProjectList = ({ items, loading, loadingMore }: ProjectListProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const onItemClick = useCallback(
    (projectId: number) => {
      if (projectId) {
        analyticsContext.trackEvent('project_selected')
        navigate(`/projects/${projectId}`)
      }
    },
    [navigate],
  )

  const emptyJsx = loading ? null : <NoData>{t('Пока что у вас нет проектов')}</NoData>

  return (
    <>
      <PageSpinner open={loading} />
      <Spinner className="list-spinner" variant="sm" open={loadingMore} />
      {items.length ? (
        <ul className="project-list">
          {items.map((project) => (
            <ProjectItem key={project.id} project={project} onClick={onItemClick} />
          ))}
        </ul>
      ) : (
        emptyJsx
      )}
    </>
  )
}

export default ProjectList

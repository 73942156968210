import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BackButton } from '@twa-dev/sdk/react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { TaskListBase } from 'blocks'
import { PageHeader, PageSpinner } from 'components'

import TaskList from './TaskList'

import { TasksStore } from 'stores'
import { ValueContext } from 'contexts'
import { isAllDef } from 'utils'

const filterKeys = ['sort', 'status', 'author', 'project']

const Assigned = observer(() => {
  const [store] = useState(() => new TasksStore.TasksAssignedStore())
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    sorting,
    pagination,
    loadConfig,
    issues,
    selectedFilters,
    loadIssues,
    saveFilters,
    loadingMore,
    loading,
    issuesLoading,
    issuesLoaded,
    configLoaded,
    hasFilters,
  } = store

  const { owners, statuses, projects } = selectedFilters

  useEffect(() => {
    loadConfig()
  }, [loadConfig])

  useEffect(() => {
    const shouldLoadIssues =
      isAllDef(sorting.key, sorting.direction, owners, statuses, projects) && configLoaded

    if (shouldLoadIssues) {
      pagination.setFirstPage()
      saveFilters()
      loadIssues()
    }
  }, [
    configLoaded,
    loadIssues,
    owners,
    pagination,
    projects,
    saveFilters,
    sorting.direction,
    sorting.key,
    statuses,
  ])

  const onBackClick = useCallback(() => {
    navigate('/')
  }, [navigate])

  const Header = (
    <>
      <BackButton onClick={onBackClick} />
      <PageHeader>{t('Задачи, где я исполнитель')}</PageHeader>
    </>
  )

  const Tasks = (
    <TaskList
      items={issues}
      loaded={issuesLoaded}
      loading={issuesLoading}
      hasFilters={hasFilters}
      loadingMore={loadingMore || issuesLoading}
    />
  )

  return (
    <ValueContext.Provider value={store}>
      <PageSpinner open={loading} />
      <TaskListBase
        contentClassName="tasks-without-button"
        filterKeys={filterKeys}
        onSearch={loadIssues}
        Header={Header}
        Tasks={Tasks}
      />
    </ValueContext.Provider>
  )
})

export default Assigned

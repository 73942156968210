import { Checkbox } from 'components'
import { RepeatItem } from 'consts'

interface Props {
  days: RepeatItem[]
  onSelect: (id: string, checked: boolean) => void
  selected: string[]
}

export const WeekDays = ({ days, selected, onSelect }: Props) => {
  return (
    <div className="week-days">
      {days.map(({ id, name }) => {
        const isSelected = selected?.includes(id)

        return (
          <Checkbox
            label={name}
            checked={isSelected}
            onChange={() => onSelect(id, !isSelected)}
            className="week-day"
            labelPosition="top"
          />
        )
      })}
    </div>
  )
}

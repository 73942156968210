import { ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import WebApp from '@twa-dev/sdk'

import { FlexLayout, NoData } from 'components'
import { useTheme } from 'utils'

import './styles.scss'

interface Props {
  children: ReactNode
}

const MIN_REQUIRED_VERSION = '8.0'

const { platform } = WebApp

const isIosOrAndroid = platform === 'ios' || platform === 'android'

const getStoreLink = (): string => {
  if (platform === 'ios') {
    return 'https://apps.apple.com/app/telegram-messenger/id686449807'
  } else if (platform === 'android') {
    return 'https://play.google.com/store/apps/details?id=org.telegram.messenger'
  } else {
    return '#'
  }
}

const isVersionSupported = (version: string, requiredVersion: string): boolean => {
  const [major, minor] = version.split('.').map(Number)
  const [reqMajor, reqMinor] = requiredVersion.split('.').map(Number)

  return major > reqMajor || (major === reqMajor && minor >= reqMinor)
}

export const VersionGuard = ({ children }: Props) => {
  const { t } = useTranslation()
  useTheme()

  if (isIosOrAndroid && !isVersionSupported(WebApp.version, MIN_REQUIRED_VERSION)) {
    return (
      <FlexLayout.Container vertical>
        <FlexLayout.Content className="scrollable-layout">
          <NoData>
            <Trans i18nKey="Ваша версия Telegram не поддерживается" components={{ 1: <br /> }} />
          </NoData>
          <a
            href={getStoreLink()}
            target="_blank"
            rel="noopener noreferrer"
            className="update-button"
          >
            {t('Обновить Telegram')}
          </a>
        </FlexLayout.Content>
      </FlexLayout.Container>
    )
  }

  return children
}

import { makeAutoObservable } from 'mobx'
import { AxiosResponse } from 'axios'
import { NavigateFunction } from 'react-router'
import set from 'lodash.set'
import merge from 'lodash.merge'

import { TaskApi } from 'api'
import { notification } from 'services'
import { IEditedTask, IProjectConfig, ITaskPerson, Nullable, TaskResponse } from 'types'
import { deepEqual, getApiError } from 'utils'
import { analyticsContext } from 'contexts'

import { createEmptyTask, parseTask, serializeTask } from './utils'

export default class TaskStore {
  projectId: string
  taskId: string
  scrollTop = 0
  navigate: NavigateFunction
  task: Nullable<IEditedTask>
  originalTask: Nullable<IEditedTask>
  loading: boolean = true
  backRoute = ''

  constructor(projectId: string, taskId: string, navigate: NavigateFunction) {
    this.projectId = projectId
    this.taskId = taskId
    this.navigate = navigate
    this.backRoute = `/projects/${projectId}`

    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get isNewTask() {
    return this.taskId === 'new'
  }

  get isTaskChanged() {
    return !deepEqual(this.task, this.originalTask)
  }

  setBackRoute = (route: string) => {
    this.backRoute = route
  }

  initEmptyTask(config: IProjectConfig, users: ITaskPerson[], currentUser: Record<string, any>) {
    // Инициализируем выпадающие списки первым значением из списка
    const { types, statuses, priorities } = config

    const type = types[0]
    const status = statuses[0]
    const priority = priorities[0]
    const owner = users.find((it) => it.login === currentUser.username) || users[0]

    const emptyTask = createEmptyTask({
      type,
      status,
      priority,
      owner,
    }) as IEditedTask

    this.task = emptyTask
    this.originalTask = emptyTask
  }

  *init(config: IProjectConfig, users: ITaskPerson[], currentUser: Record<string, any>) {
    if (this.isNewTask) {
      this.loading = false
      this.initEmptyTask(config, users, currentUser)
    } else {
      yield this.loadById(config.timeZoneOffset)
    }
  }

  *loadById(timeZone: string = '') {
    if (this.taskId) {
      this.loading = true

      try {
        const response: AxiosResponse<TaskResponse> = yield TaskApi.getById(
          this.projectId,
          this.taskId,
        )
        const data = parseTask(response.data.data, timeZone)

        this.task = data
        this.originalTask = data
      } catch (ex: any) {
        notification.error('Ошибка загрузки задачи')
        console.log(ex?.message)
      } finally {
        this.loading = false
      }
    }
  }

  *save(timeZone: string) {
    if (this.projectId) {
      this.loading = true

      try {
        const serialized = serializeTask(this.task, timeZone)

        if (this.isNewTask) {
          yield TaskApi.create(this.projectId, serialized)
          analyticsContext.trackEvent('task_created')
        } else {
          yield TaskApi.edit(this.projectId, this.taskId, serialized)
          analyticsContext.trackEvent('task_edited')
        }
        notification.success('Задача сохранена')
        this.navigate(this.backRoute, { replace: true })
      } catch (ex: any) {
        notification.error({ title: 'Ошибка сохранения задачи', description: getApiError(ex) })
        console.log(ex?.message)
        analyticsContext.trackEvent('task_created', { create_task_error: ex?.message })
      } finally {
        this.loading = false
      }
    }
  }

  *remove() {
    if (this.taskId) {
      this.loading = true

      try {
        yield TaskApi.remove(this.projectId, this.taskId)

        if (this.projectId) {
          notification.success('Задача удалена')
          this.navigate(this.backRoute, { replace: true })
        }
      } catch (ex: any) {
        notification.error('Не удалось удалить задачу')
        console.log(ex?.message)
      } finally {
        this.loading = false
      }
    }
  }

  setTempNotificationFields = (fields: Partial<IEditedTask>) => {
    if (this.task) {
      merge(this.task.tempNotification, fields)
    }
  }

  setTaskField = (field: keyof IEditedTask, value: any) => {
    if (this.task) {
      set(this.task, field, value)
    }
  }

  setTaskFields = (fields: Partial<IEditedTask>) => {
    if (this.task) {
      merge(this.task, fields)
    }
  }

  setScrollTop = (value: number) => {
    this.scrollTop = value
  }
}

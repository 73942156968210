import { useEffect, useMemo, useRef } from 'react'
import JoditEditor from 'jodit-react'
import { useTranslation } from 'react-i18next'

import './styles.scss'
import { config, handleBrokenImages } from './utils'

interface Props {
  value: string
  onChange: (value: string) => void
}

export const Editor = (props: Props) => {
  const { value, onChange } = props
  const { i18n } = useTranslation()
  const ref = useRef(null)
  const editorConfig: Record<string, any> = useMemo(() => {
    return {
      ...config,
      language: i18n.language,
    }
  }, [i18n.language])

  // Через событие редактора afterInsertImage удаление не всегда работает по каким-то причинам,
  // Поэтому отслеживаем добавление картинок в редактор напрямую
  useEffect(() => {
    const observer = handleBrokenImages(onChange)
    return () => observer.disconnect()
  }, [onChange])

  return (
    <div className="editor-wrapper">
      <JoditEditor ref={ref} config={editorConfig} value={value} onChange={onChange} />
    </div>
  )
}

import { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { ToastContainer } from 'react-toastify'
import { RouterProvider } from 'react-router-dom'
import { AppRoot } from '@telegram-apps/telegram-ui'
import '@telegram-apps/telegram-ui/dist/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import { TrackGroups, TwaAnalyticsProvider } from '@tonsolutions/telemetree-react'
import WebApp from '@twa-dev/sdk'

import { AppProvider } from 'contexts'
import { VersionGuard } from 'guards'

import './assets/styles/index.scss'
import './i18n'

import { TELEMETREE_API_KEY, TELEMETREE_PROJECT_ID } from './consts'
import router from './router'

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <VersionGuard>
      <TwaAnalyticsProvider
        projectId={TELEMETREE_PROJECT_ID}
        apiKey={TELEMETREE_API_KEY}
        telegramWebAppData={WebApp.initDataUnsafe}
        trackGroup={TrackGroups.HIGH}
      >
        <AppProvider>
          <AppRoot>
            <Suspense fallback="">
              <RouterProvider router={router} />
              <ToastContainer />
            </Suspense>
          </AppRoot>
        </AppProvider>
      </TwaAnalyticsProvider>
    </VersionGuard>
  </StrictMode>,
)

import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import WebApp from '@twa-dev/sdk'
import { useTWAEvent } from '@tonsolutions/telemetree-react'
import { init } from '@amplitude/analytics-browser'

import { useApp, useTheme } from 'utils'
import { Button, PageSpinner } from 'components'
import { analyticsContext, translationContext } from 'contexts'
import { AMPLITUDE_API_KEY } from 'consts'

import './styles.scss'

const isTouchable = 'ontouchstart' in window || navigator.maxTouchPoints > 0

const AppLayout = observer(() => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { initialized, initDataUnsafe } = useApp()
  const builder = useTWAEvent()

  useEffect(() => {
    translationContext.t = t
  }, [t])

  useEffect(() => {
    analyticsContext.telemetreeBuilder = builder
  }, [builder])

  useEffect(() => {
    init(AMPLITUDE_API_KEY, initDataUnsafe?.user?.id, { serverZone: 'EU', autocapture: true })
  }, [initDataUnsafe?.user?.id])

  useEffect(() => {
    if (isTouchable) {
      WebApp.requestFullscreen()
      document.body.classList.add('touchable')
    } else {
      WebApp.expand()
    }
  }, [])

  useEffect(() => {
    const startApp = WebApp.initDataUnsafe.start_param || ''
    const match = startApp.match(/projects_(-?\d+)/)
    const mathProjectIssues = startApp.match(/projects_(-?\d+)_issues_(\d+)/)
    const matchCampaign = startApp.match(/campaign=([^_]*)/)

    if (startApp) {
      analyticsContext.trackEvent('app_launched_via_link', { start_param: startApp })
    }

    if (matchCampaign) {
      analyticsContext.trackEvent('app_launched_via_campaign', { campaign: matchCampaign?.[1] })
    }

    if (mathProjectIssues) {
      const projectId = mathProjectIssues[1]
      const issuesId = mathProjectIssues[2]
      navigate(`/projects/${projectId}/tasks/${issuesId}`, { replace: true })
    } else if (match) {
      const projectId = match[1]
      navigate(`/projects/${projectId}`, { replace: true })
    }
  }, [navigate])

  useTheme()

  // TODO: удалить кнопку и функцию после теста и добавления всех значений
  const onChangeLang = () => {
    let lang = ''

    switch (i18n.language) {
      case 'en':
        lang = 'es'
        break
      case 'es':
        lang = 'ru'
        break
      case 'ru':
        lang = 'en'
    }

    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    i18n.changeLanguage(WebApp?.initDataUnsafe?.user?.language_code || 'en')
  }, [i18n])

  // Фикс для ios. При появлении клавиатуры и последующем ее скрытии
  // в приложении появляется скролл - убираем его
  useEffect(() => {
    const onViewportChange = () => {
      document.documentElement.scrollTop = 0
    }

    WebApp.onEvent('viewportChanged', onViewportChange)

    return () => {
      WebApp.offEvent('viewportChanged', onViewportChange)
    }
  }, [])

  return (
    <div className="full-screen">
      <Button
        style={{
          display: 'none', // Удалять для теста перевода
          position: 'fixed',
          zIndex: 1,
          top: isTouchable ? 'var(--mobile-top-indent)' : 0,
        }}
        onClick={onChangeLang}
      >
        {i18n.language}
      </Button>
      {initialized ? <Outlet /> : <PageSpinner delay={300} minVisibleTime={300} open opaque />}
    </div>
  )
})

export default AppLayout

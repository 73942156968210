import { AxiosResponse } from 'axios'
import { NavigateFunction } from 'react-router'
import { action, flow, makeObservable, observable } from 'mobx'

import { ProjectApi } from 'api'
import { notification } from 'services'
import { analyticsContext, translationContext } from 'contexts'
import { isDefAndNotEmpty, uniqBy } from 'utils'
import {
  IProject,
  ProjectConfigResponse,
  ProjectIssuesResponse,
  ProjectResponse,
  ProjectUsersResponse,
} from 'types'

import BaseTasksStore, { defaultConfig } from '../BaseTasksStore'

const initialSelectedFilters = {
  assignees: [],
  statuses: [],
  owners: [],
}

export default class ProjectStore extends BaseTasksStore {
  project: IProject
  projectId: number
  navigate: NavigateFunction
  firstLoaded = true

  constructor(projectId: string, navigate: NavigateFunction) {
    super()
    // @ts-expect-error Сделать функцию инициализации пустого проекта на основе IProject
    this.project = {}
    this.projectId = Number(projectId)
    this.settingsKey = `project-${this.projectId}`
    this.selectedFilters = JSON.parse(JSON.stringify(initialSelectedFilters))
    this.initialSelectedFilters = initialSelectedFilters
    this.navigate = navigate

    makeObservable(this, {
      project: observable,
      projectId: observable,
      navigate: observable,
      initDictsIfEmpty: flow.bound,
      loadById: flow.bound,
      loadIssues: flow.bound,
      loadMoreIssues: flow.bound,
      loadConfig: flow.bound,
      loadUsers: flow.bound,
      saveConfig: flow.bound,
      setTimezone: action,
    })

    this.applySavedFilters()
  }

  *initDictsIfEmpty() {
    const promises = []

    if (this.isEmptyConfig) {
      promises.push(this.loadConfig(this.projectId))
    }
    if (this.isEmptyUsers) {
      promises.push(this.loadUsers(this.projectId))
    }

    yield Promise.allSettled(promises)
  }

  *loadById(projectId: number) {
    if (Number.isFinite(projectId)) {
      try {
        this.loading = true
        const response: AxiosResponse<ProjectResponse> = yield ProjectApi.getById(projectId)
        this.project = response.data.data
      } catch (ex: any) {
        if (ex?.status === 404) {
          notification.error(
            `${translationContext.t('Проект не найден')}. ${translationContext.t('Пожалуйста, удалите и снова добавьте бота в группу')}`,
            {
              autoClose: 4000,
            },
          )
        } else {
          notification.error('Ошибка загрузки проекта')
        }
        this.navigate('/projects', { replace: true })
      } finally {
        this.loading = false
      }
    }
  }

  *loadIssues(projectId: number) {
    if (Number.isFinite(projectId)) {
      this.issuesLoading = true
      this.issuesLoaded = false

      try {
        const response: AxiosResponse<ProjectIssuesResponse> = yield ProjectApi.getIssuesById(
          projectId,
          this.getListParams(),
        )

        const { data, currentPage, totalPages } = response.data

        this.issues = data

        if (this.firstLoaded) {
          const analyticsOptions: Record<string, any> = { project_ID: projectId }

          if (!isDefAndNotEmpty(data)) {
            analyticsOptions.no_tasks = true
          }

          analyticsContext.trackEvent('project_viewed', analyticsOptions)
          this.firstLoaded = false
        }

        this.pagination.setCurrentPage(currentPage)
        this.pagination.totalPages = totalPages
      } catch (ex: any) {
        notification.error('Ошибка загрузки задач')
        console.log(ex?.message)
      } finally {
        this.issuesLoading = false
        this.issuesLoaded = true
      }
    }
  }

  *loadMoreIssues() {
    const isEndOfData = this.pagination.currentPage > this.pagination.totalPages

    if (Number.isFinite(this.projectId) && !isEndOfData && !this.loadingMore) {
      this.loadingMore = true

      try {
        const response: AxiosResponse<ProjectIssuesResponse> = yield ProjectApi.getIssuesById(
          this.projectId,
          this.getListParams(),
        )

        const { data, currentPage } = response.data

        this.issues = uniqBy('id', [...this.issues, ...data])
        this.pagination.setCurrentPage(currentPage)
      } catch (ex: any) {
        notification.error('Ошибка загрузки задач')
        console.log(ex?.message)
      } finally {
        this.loadingMore = false
      }
    }
  }

  *loadConfig(projectId: number) {
    if (Number.isFinite(projectId)) {
      try {
        const response: AxiosResponse<ProjectConfigResponse> =
          yield ProjectApi.getConfigById(projectId)

        this.config = response.data.data || defaultConfig
      } catch (ex: any) {
        console.log(ex?.message)
      }
    }
  }

  *loadUsers(projectId: number) {
    if (Number.isFinite(projectId)) {
      try {
        const response: AxiosResponse<ProjectUsersResponse> =
          yield ProjectApi.getUsersById(projectId)

        this.users = response.data.data || []
      } catch (ex: any) {
        console.log(ex?.message)
      }
    }
  }

  *saveConfig() {
    if (Number.isFinite(this.projectId)) {
      this.loading = true

      try {
        yield ProjectApi.saveConfig(this.projectId, this.config)

        notification.success('Изменения сохранены')
      } catch (ex: any) {
        notification.error('Не удалось сохранить изменения')
        console.log(ex?.message)
      } finally {
        this.loading = false
      }
    }
  }

  setTimezone = (value: string) => {
    this.config.timeZoneOffset = value
  }
}

import cn from 'classnames'

import { Spinner } from '../UI'
import './styles.scss'
import { useEffect, useRef, useState } from 'react'

interface Props {
  open: boolean
  opaque?: boolean
  delay?: number

  // Минимальное время, в течение которого лоадер должен быть видимым.
  // Бывают ситуации, когда ставим delay = 300, а запрос завершается за 350 мс,
  // и лоадер все равно показывается на 50мс, что вызывает мерцание.
  // Этот параметр призван убрать это поведение.
  minVisibleTime?: number
}

const PageSpinner = ({ open = false, delay = 0, minVisibleTime = 0, opaque }: Props) => {
  const [shown, setShown] = useState(false)
  const timer = useRef<NodeJS.Timeout | null>(null)
  const hideTimer = useRef<NodeJS.Timeout | null>(null)

  // Хук для исправления мерцающего индикатора, когда его время отображения мало (anti-flickering)
  useEffect(() => {
    const clearTimers = () => {
      if (timer.current) clearTimeout(timer.current)
      if (hideTimer.current) clearTimeout(hideTimer.current)
      timer.current = null
      hideTimer.current = null
    }

    if (open) {
      timer.current = setTimeout(() => {
        setShown(true)
        if (minVisibleTime > 0 && !hideTimer.current) {
          hideTimer.current = setTimeout(() => {
            if (!open) {
              setShown(false)
            }
          }, minVisibleTime)
        }
      }, delay)
    } else {
      if (shown && minVisibleTime > 0 && !hideTimer.current) {
        hideTimer.current = setTimeout(() => setShown(false), minVisibleTime)
      } else {
        setShown(false)
      }
    }

    return () => clearTimers()
  }, [open, delay, minVisibleTime, shown])

  return shown ? (
    <div className={cn('page-spinner', { opaque })}>
      <Spinner open={shown} />
    </div>
  ) : null
}

export default PageSpinner

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import WebApp from '@twa-dev/sdk'

import { BottomFooter, CreateButton } from 'components'
import { analyticsContext } from 'contexts'
import { botName } from 'consts'

import './styles.scss'

const Footer = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault()

    if (WebApp.platform.includes('web')) {
      WebApp.showAlert(t('Эта функция не поддерживается'))
      return
    }

    const event =
      location.pathname === '/' ? 'add_new_project_via_home' : 'add_new_project_via_projects'

    analyticsContext.trackEvent(event)

    WebApp.openTelegramLink(
      `https://t.me/${botName}?startgroup=setup&admin=pin_messages+promote_members`,
    )
  }

  return (
    <BottomFooter className="home-bottom-footer">
      <CreateButton to="" onClick={onClick}>
        {t('Создать проект')}
      </CreateButton>
    </BottomFooter>
  )
}

export default Footer

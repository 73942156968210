import { useTranslation } from 'react-i18next'

import { BottomFooter, CreateButton } from 'components'
import { analyticsContext } from 'contexts'

interface Props {
  to?: string
}

const onClick = () => {
  analyticsContext.trackEvent('add_task')
}

export const TaskCreateFooter = ({ to = 'tasks/new' }: Props) => {
  const { t } = useTranslation()

  return (
    <BottomFooter className="mb-3">
      <CreateButton onClick={onClick} to={to}>
        {t('Создать задачу')}
      </CreateButton>
    </BottomFooter>
  )
}

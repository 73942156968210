import { ReactNode } from 'react'

import { Checkbox } from 'components'

interface Props {
  onClick: () => void
  selected: number[]
  value: number
  className?: string
  children?: ReactNode
}

export const Option = (props: Props) => {
  const { onClick, selected, value, className, children } = props

  return (
    <Checkbox
      className={className}
      checked={selected.includes(value)}
      label={children}
      onChange={onClick}
      labelPosition="left"
    />
  )
}

import React, { CSSProperties, ReactNode } from 'react'
import cn from 'classnames'

import './styles.scss'

const labelPositions = ['left', 'right', 'top'] as const
type LabelPosition = (typeof labelPositions)[number]

interface Props {
  className?: string
  label?: ReactNode
  gap?: number
  style?: CSSProperties
  labelPosition?: LabelPosition
  onChange?: (v: boolean) => void
  checked?: boolean
  disabled?: boolean
}

export const Checkbox = (props: Props) => {
  const {
    label,
    style,
    labelPosition = 'right',
    gap = 16,
    className,
    onChange,
    disabled,
    checked,
    ...rest
  } = props

  const validPosition: string = labelPositions.includes(labelPosition) ? labelPosition : 'right'

  return (
    <>
      <label
        className={cn(
          'checkbox',
          validPosition,
          {
            'no-label': !label,
            disabled,
          },
          className,
        )}
        style={{ gap, ...style }}
      >
        <div>
          <input
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange?.(e.target.checked)}
            {...rest}
          />
          <div className="visual-checkbox" />
        </div>
        {label && <div className="label">{label}</div>}
      </label>
    </>
  )
}

import { format, setHours, setMinutes } from 'date-fns'

import { IEditedTask, ITaskNotification } from 'types'
import { fromZoned, isTimeValid, toZoned } from 'utils'

type EmptyTaskParams = {
  type?: Record<string, any>
  status?: Record<string, any>
  priority?: Record<string, any>
  owner?: Record<string, any>
}

export const createEmptyTask = (params?: EmptyTaskParams): Record<string, any> => {
  const { type, status, priority, owner } = params ?? {}

  return {
    title: '',
    description: '',
    notification: {
      type: 'Never',
    },
    type,
    status,
    priority,
    owner,
    assignedTo: owner,
    isNotificationValid: true,
    tags: [],
  }
}

const serializeNotificationTime = ({
  date,
  time,
  timeZone,
}: {
  date: Date
  time: string
  timeZone: string
}): string => {
  if (!date) return ''

  const validTime = isTimeValid(time) ? time : '00:00'
  const [hours, minutes] = validTime.split(':').map(Number)

  const withTime = setHours(setMinutes(date, minutes), hours)
  const fromZonedTime = fromZoned(withTime, timeZone)
  return fromZonedTime.toISOString()
}

const serializeNotification = (
  notification: ITaskNotification,
  timeZone: string,
): ITaskNotification => {
  if (!notification) return { type: 'Never' }

  const notificationObj = { ...notification }

  if (notification.type !== 'Never') {
    notificationObj.start = serializeNotificationTime({
      date: (notification.date as Date) || new Date(),
      time: notification.start as string,
      timeZone,
    })
  }

  // Вспомогательное поле для фронта, на бэке не нужно
  delete notificationObj.date

  return notificationObj
}

export const serializeTask = (task: any, timeZone: string): IEditedTask => {
  if (!task) return {} as IEditedTask

  const { id, title, description, type, status, priority, assignedTo, owner, tags, notification } =
    task

  const serializedNotification = serializeNotification(notification, timeZone)

  return {
    id,
    tags,
    title,
    description,
    typeId: type?.id,
    priorityId: priority?.id,
    statusId: status?.id,
    assignedToId: assignedTo?.id,
    reporterToId: owner?.id,
    notification: serializedNotification,
  }
}

export const parseNotification = (
  notification: ITaskNotification,
  timeZone: string,
): ITaskNotification => {
  const notificationObj = {
    ...notification,
    type: notification?.type || 'Never',
  }

  if (notification?.type !== 'Never') {
    const notificationStart = notification?.start || new Date().toISOString()

    const date = toZoned(new Date(notificationStart), timeZone)
    const time = format(date, 'HH:mm')

    notificationObj.date = date
    notificationObj.start = time
  }

  if (notification?.repeatDays) {
    notificationObj.repeatDays =
      notification?.repeatDays?.map((it: string) => it.toLowerCase()) || []
  }

  return notificationObj
}

export const parseTask = (task: any, timeZone: string): IEditedTask => {
  return {
    ...task,
    notification: parseNotification(task?.notification, timeZone),
    isNotificationValid: true,
  }
}

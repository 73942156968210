import { makeAutoObservable } from 'mobx'
import WebApp from '@twa-dev/sdk'
import type { WebAppInitData } from '@twa-dev/types'

import AuthStore from './AuthStore'
import { UserApi } from '../api'

export default class AppStore {
  initData: string
  initDataUnsafe: WebAppInitData
  auth
  isBotInContacts = false
  isBotInContactsLoading = true

  constructor() {
    this.initData = WebApp.initData
    this.initDataUnsafe = WebApp.initDataUnsafe
    this.auth = new AuthStore()

    this.init()

    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get initialized() {
    return this.auth.initialized
  }

  *getIsBotInContacts() {
    try {
      this.isBotInContactsLoading = true
      const { data } = yield UserApi.isBotInContacts()

      this.isBotInContacts = data.data
    } catch (ex: any) {
      console.log(ex?.message)
    } finally {
      this.isBotInContactsLoading = false
    }
  }

  init() {
    if (process.env.NODE_ENV === 'production') {
      this.auth.loadToken(this.initData)
    } else {
      this.auth.loadTokenInDev(this.initDataUnsafe.user, this.initDataUnsafe.hash)
    }
  }
}

import { track } from '@amplitude/analytics-browser'

import { Nullable } from 'types'

interface EventBuilder {
  track(eventName: string, eventProperties: Record<string, any>): Promise<void>
}

class AnalyticsContext {
  #builder: Nullable<EventBuilder> = null // Приватное поле

  constructor(builder?: EventBuilder) {
    this.#builder = builder || null
  }

  get telemetreeBuilder(): EventBuilder {
    if (!this.#builder) {
      throw new Error('Translation function is not set')
    }
    return this.#builder
  }

  set telemetreeBuilder(builder: EventBuilder) {
    if (this.#builder === null) {
      this.#builder = builder
    }
  }

  trackEvent(eventName: string, eventProperties: Record<string, any> = {}) {
    try {
      this.#builder?.track(eventName, eventProperties)
    } catch (error) {
      console.error('Telemetree tracking error:', error)
    }

    try {
      track(eventName, eventProperties)
    } catch (error) {
      console.error('Amplitude tracking error:', error)
    }
  }
}

export const analyticsContext = new AnalyticsContext()

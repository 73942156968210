import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import WebApp from '@twa-dev/sdk'

import { UserStore } from 'stores'
import { Avatar, FlexLayout, PageHeader } from 'components'
import { analyticsContext } from 'contexts'

import defaultAvatar from './defaultAvatar.png'

import './styles.scss'

const docsLangMap: Record<string, string> = {
  ru: 'rus',
  en: 'eng',
  es: 'esp',
}

const User = observer(() => {
  const [store] = useState(() => new UserStore(WebApp.initDataUnsafe.user))
  const { i18n, t } = useTranslation()

  useEffect(() => {
    analyticsContext.trackEvent('home_page_viewed')
  }, [])

  const buttons = useMemo(
    () => [
      {
        icon: '🦸',
        text: 'Задачи, где я исполнитель',
        link: '/tasks-assigned',
      },
      {
        icon: '👨‍💼',
        text: 'Задачи, где я автор',
        link: '/tasks-created',
      },
      {
        icon: '🔒',
        text: 'Мои личные задачи',
        link: '/tasks-personal',
      },
      {
        icon: '📚',
        text: 'Инструкции',
        link: `https://doc.squad2.com/${docsLangMap[i18n.language] || 'eng'}`,
        instantView: true,
      },
      {
        icon: '💬',
        text: 'Обратная связь',
        link: 'https://t.me/squad2_Support',
        target: '_blank',
      },
    ],
    [i18n.language],
  )

  return (
    <FlexLayout.Container className="user-page" vertical>
      <Avatar src={store.avatar || defaultAvatar} alt={`${store.fullName}'s avatar`} size={48} />

      <PageHeader className="regular-indent">{store.fullName}</PageHeader>

      <FlexLayout.Content className="user-content" scrollable>
        {buttons.map(({ icon, text, link, target, instantView }) => {
          const onClick = instantView
            ? (e: React.MouseEvent) => {
                e.preventDefault()
                WebApp.openLink(link, { try_instant_view: true })
              }
            : undefined

          return (
            <Link key={text} to={link} target={target} className="user-option" onClick={onClick}>
              {icon} {t(text)}
            </Link>
          )
        })}
      </FlexLayout.Content>
    </FlexLayout.Container>
  )
})

export default User

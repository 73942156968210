import { ReactNode, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Trans, useTranslation } from 'react-i18next'

import { useApp } from 'utils'
import { botName } from 'consts'

import './styles.scss'

interface Props {
  children?: ReactNode
}

export const BotActiveRoute = observer(({ children }: Props) => {
  const { initialized, getIsBotInContacts, isBotInContacts, isBotInContactsLoading } = useApp()
  const { t } = useTranslation()

  useEffect(() => {
    if (initialized && !isBotInContacts) {
      getIsBotInContacts()
    }
  }, [initialized, getIsBotInContacts, isBotInContacts])

  return (
    <>
      {isBotInContacts ? (
        children
      ) : (
        <div className="add-bot-warning">
          <span>
            {isBotInContactsLoading ? (
              `${t('Проверка наличия бота в контакт-листе')}...`
            ) : (
              <Trans i18nKey="Добавьте бота в ваш контакт-лист">
                Добавьте <Link to={`https://t.me/${botName}`} /> в ваш контакт-лист
              </Trans>
            )}
          </span>
        </div>
      )}
    </>
  )
})

import type { WebAppUser } from '@twa-dev/types'

import { apiService } from 'services'

import { TokenResponse } from 'types'

const getTokenInDev = async (data: { user: WebAppUser; hash: string }) => {
  return apiService.post<TokenResponse>('/auth', data)
}

const getToken = async (initData: string) => {
  const formData = new FormData()
  formData.append('initData', initData)

  return apiService.post<TokenResponse>('/auth/auth2', formData, {
    headers: {
      accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  })
}

const AuthApi = { getToken, getTokenInDev }

export default AuthApi
